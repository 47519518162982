html {
	scroll-behavior: smooth;
}

html,
body {
	font-family: 'Poppins', sans-serif;
}

body {
	margin: 0px;
	background-color: #212121;
	color: white;
}

.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
