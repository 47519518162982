.main-container {
	padding: 2px;
	border-radius: 30px;
	/* background-color: #f50057; */
}
.game-container {
	position: relative;
	border-radius: inherit;
	width: 500px;
	height: 500px;
	background-color: #f50057;
}
.tile {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100px;
	height: 100px;
	color: #212121;
	font-size: 20px;
	font-weight: 800;
	background-color: #1de9b6;
	box-shadow: 0 0 0 2px #f50057 inset;
	border-radius: inherit;
	transition: all 0.5s;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column !important;
	-webkit-box-align: center;
	-webkit-box-pack: center;
}

.flex-container {
	display: flex;
	flex-wrap: nowrap;
}

.flex-center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.time {
	font-family: 'Roboto Mono', monospace;
}

@media only screen and (max-width: 700px) {
	.main-container {
		border-radius: 20px;
	}
	.game-container {
		width: 250px;
		height: 250px;
	}
	.tile {
		width: 50px;
		height: 50px;
	}
}
